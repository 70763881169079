<template>
  <div class="animated fadeIn">
    <div id="snackbar"></div>
    <b-card>
      <b-form>
        <b-row>
          <b-col sm="3">
            <b-form-group>
              <label>Select .xlxs file</label>
              <b-form-file
                type="file"
                v-on:change="handleFile($event.target)"
                id="readexcel"
                v-model="ReadExcel"
              ></b-form-file>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group>
              <label for="from-date">From Date :</label>
              <b-form-input type="date" v-model="FromDate"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="3">
            <b-form-group>
              <label for="to-date">To Date :</label>
              <b-form-input type="date" v-model="ToDate"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="3">
            <b-form-group>
              <b-button
                @click.prevent.stop="submit"
                class="mt-4"
                type="submit"
                size="sm"
                variant="primary"
              >
                <i class="fa fa-dot-circle-o"></i> Get Reports
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>

      <!-- Unreconciled Data -->

      <b-card-header>
        <b-row>
          <b-col>
            <i class="icon-menu mr-1"></i>Non Reconciled Data
          </b-col>
          <b-col class="col d-flex justify-content-end">
            <!--<b-button
              v-if="data.length > 0"
              :disabled="data.length > 0 ? false: true"
              v-on:click="onDownloadPDF('PDF')"
              type="submit"
              size="sm"
              variant="primary"
            >
              <i class="fa fa-dot-circle-o"></i>Download as PDF
            </b-button>-->
            &nbsp;&nbsp;
            <b-button
              v-if="NonReconciled.length > 0"
              :disabled="NonReconciled.length > 0 ? false: true"
              v-on:click="onDownloadExcel('XLSX')"
              type="submit"
              size="sm"
              variant="primary"
            >
              <i class="fa fa-dot-circle-o"></i>Download as XLSX
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <div>
          <v-client-table
            :columns="columns"
            :data="NonReconciled"
            :options="options"
            :theme="theme"
            id="dataTable"
          >
            <div slot="Action" slot-scope="props">
              <b-button :disabled="props.row.Flag" type="submit" variant="success" block>Refund</b-button>
            </div>
            <!--<div slot="Action" slot-scope="props">
              <b-button
                :disabled="props.row.Flag"
                @click="submitTransferId(props.index,props.row.BookingID)"
                type="submit"
                variant="success"
                block
              >Resend Ticket</b-button>
            </div>-->
          </v-client-table>
        </div>
      </b-card-body>

      <!-- Unreconciled Data -->
      <!-- Unmatch Records -->
      <b-card-header>
        <b-row>
          <b-col>
            <i class="icon-menu mr-1"></i>UnMatch Transactions From Excel
          </b-col>
          <b-col class="col d-flex justify-content-end">
            <!--<b-button
              v-if="data.length > 0"
              :disabled="data.length > 0 ? false: true"
              v-on:click="onDownloadPDF('PDF')"
              type="submit"
              size="sm"
              variant="primary"
            >
              <i class="fa fa-dot-circle-o"></i>Download as PDF
            </b-button>-->
            &nbsp;&nbsp;
            <b-button
              v-if="UnMatchData.length > 0"
              :disabled="UnMatchData.length > 0 ? false: true"
              v-on:click="onDownloadExcel('XLSX')"
              type="submit"
              size="sm"
              variant="primary"
            >
              <i class="fa fa-dot-circle-o"></i>Download as XLSX
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <div>
          <v-client-table
            :columns="columns"
            :data="UnMatchData"
            :options="options"
            :theme="theme"
            id="dataTable"
          >
            <div slot="Action" slot-scope="props">
              <b-button :disabled="props.row.Flag" type="submit" variant="success" block>Refund</b-button>
            </div>
            <!--<div slot="Action" slot-scope="props">
              <b-button
                :disabled="props.row.Flag"
                @click="submitTransferId(props.index,props.row.BookingID)"
                type="submit"
                variant="success"
                block
              >Resend Ticket</b-button>
            </div>-->
          </v-client-table>
        </div>
      </b-card-body>
      <!-- Unmatch Records -->

      <!-- Unmatch Records From DB -->
      <b-card-header>
        <b-row>
          <b-col>
            <i class="icon-menu mr-1"></i>UnMatch Transactions From DB
          </b-col>
          <b-col class="col d-flex justify-content-end">
            <!--<b-button
              v-if="data.length > 0"
              :disabled="data.length > 0 ? false: true"
              v-on:click="onDownloadPDF('PDF')"
              type="submit"
              size="sm"
              variant="primary"
            >
              <i class="fa fa-dot-circle-o"></i>Download as PDF
            </b-button>-->
            &nbsp;&nbsp;
            <b-button
              v-if="UnMatchDataFromDB.length > 0"
              :disabled="UnMatchDataFromDB.length > 0 ? false: true"
              v-on:click="onDownloadExcel('XLSX')"
              type="submit"
              size="sm"
              variant="primary"
            >
              <i class="fa fa-dot-circle-o"></i>Download as XLSX
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <div>
          <v-client-table
            :columns="columns"
            :data="UnMatchDataFromDB"
            :options="options"
            :theme="theme"
            id="dataTable"
          >
            <div slot="Action" slot-scope="props">
              <b-button :disabled="props.row.Flag" type="submit" variant="success" block>Refund</b-button>
            </div>
            <!--<div slot="Action" slot-scope="props">
              <b-button
                :disabled="props.row.Flag"
                @click="submitTransferId(props.index,props.row.BookingID)"
                type="submit"
                variant="success"
                block
              >Resend Ticket</b-button>
            </div>-->
          </v-client-table>
        </div>
      </b-card-body>
      <!-- Unmatch Records From DB -->

      <!-- Unmatch Amount -->
      <b-card-header>
        <b-row>
          <b-col>
            <i class="icon-menu mr-1"></i>UnMatch Amount Transactions From Excel
          </b-col>
          <b-col class="col d-flex justify-content-end">
            <!--<b-button
              v-if="data.length > 0"
              :disabled="data.length > 0 ? false: true"
              v-on:click="onDownloadPDF('PDF')"
              type="submit"
              size="sm"
              variant="primary"
            >
              <i class="fa fa-dot-circle-o"></i>Download as PDF
            </b-button>-->
            &nbsp;&nbsp;
            <b-button
              v-if="UnMatchAmount.length > 0"
              :disabled="UnMatchAmount.length > 0 ? false: true"
              v-on:click="onDownloadExcel('XLSX')"
              type="submit"
              size="sm"
              variant="primary"
            >
              <i class="fa fa-dot-circle-o"></i>Download as XLSX
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <div>
          <v-client-table
            :columns="columns"
            :data="UnMatchAmount"
            :options="options"
            :theme="theme"
            id="dataTable"
          >
            <div slot="Action" slot-scope="props">
              <b-button :disabled="props.row.Flag" type="submit" variant="success" block>Refund</b-button>
            </div>
            <!--<div slot="Action" slot-scope="props">
              <b-button
                :disabled="props.row.Flag"
                @click="submitTransferId(props.index,props.row.BookingID)"
                type="submit"
                variant="success"
                block
              >Resend Ticket</b-button>
            </div>-->
          </v-client-table>
        </div>
      </b-card-body>
      <!-- Unmatch Amount -->

      <!-- Unmatch Amount From DB -->
      <b-card-header>
        <b-row>
          <b-col>
            <i class="icon-menu mr-1"></i>UnMatch Amount Transactions From DB
          </b-col>
          <b-col class="col d-flex justify-content-end">
            <!--<b-button
              v-if="data.length > 0"
              :disabled="data.length > 0 ? false: true"
              v-on:click="onDownloadPDF('PDF')"
              type="submit"
              size="sm"
              variant="primary"
            >
              <i class="fa fa-dot-circle-o"></i>Download as PDF
            </b-button>-->
            &nbsp;&nbsp;
            <b-button
              v-if="UnMatchAmountFromDB.length > 0"
              :disabled="UnMatchAmountFromDB.length > 0 ? false: true"
              v-on:click="onDownloadExcel('XLSX')"
              type="submit"
              size="sm"
              variant="primary"
            >
              <i class="fa fa-dot-circle-o"></i>Download as XLSX
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <div>
          <v-client-table
            :columns="columns"
            :data="UnMatchAmountFromDB"
            :options="options"
            :theme="theme"
            id="dataTable"
          >
            <div slot="Action" slot-scope="props">
              <b-button :disabled="props.row.Flag" type="submit" variant="success" block>Refund</b-button>
            </div>
            <!--<div slot="Action" slot-scope="props">
              <b-button
                :disabled="props.row.Flag"
                @click="submitTransferId(props.index,props.row.BookingID)"
                type="submit"
                variant="success"
                block
              >Resend Ticket</b-button>
            </div>-->
          </v-client-table>
        </div>
      </b-card-body>
      <!-- Unmatch Amount From DB -->

      <!-- Excel Data -->

      <b-card-header>
        <b-row>
          <b-col>
            <i class="icon-menu mr-1"></i>Excel Data
          </b-col>
          <b-col class="col d-flex justify-content-end">
            <!--<b-button
              v-if="data.length > 0"
              :disabled="data.length > 0 ? false: true"
              v-on:click="onDownloadPDF('PDF')"
              type="submit"
              size="sm"
              variant="primary"
            >
              <i class="fa fa-dot-circle-o"></i>Download as PDF
            </b-button>-->
            &nbsp;&nbsp;
            <b-button
              v-if="data.length > 0"
              :disabled="data.length > 0 ? false: true"
              v-on:click="onDownloadExcel('XLSX')"
              type="submit"
              size="sm"
              variant="primary"
            >
              <i class="fa fa-dot-circle-o"></i>Download as XLSX
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <div>
          <v-client-table
            :columns="columns"
            :data="data"
            :options="options"
            :theme="theme"
            id="dataTable"
          >
            <div slot="Action" slot-scope="props">
              <b-button :disabled="props.row.Flag" type="submit" variant="success" block>Refund</b-button>
            </div>
            <!--<div slot="Action" slot-scope="props">
              <b-button
                :disabled="props.row.Flag"
                @click="submitTransferId(props.index,props.row.BookingID)"
                type="submit"
                variant="success"
                block
              >Resend Ticket</b-button>
            </div>-->
          </v-client-table>
        </div>
      </b-card-body>

      <!-- Excel Data -->

      <!-- Data From DB -->
      <b-card-header>
        <b-row>
          <b-col>
            <i class="icon-menu mr-1"></i>Data From DB
          </b-col>
          <b-col class="col d-flex justify-content-end">
            <!--<b-button
              v-if="data.length > 0"
              :disabled="data.length > 0 ? false: true"
              v-on:click="onDownloadPDF('PDF')"
              type="submit"
              size="sm"
              variant="primary"
            >
              <i class="fa fa-dot-circle-o"></i>Download as PDF
            </b-button>-->
            &nbsp;&nbsp;
            <b-button
              v-if="DataFromDB.length > 0"
              :disabled="DataFromDB.length > 0 ? false: true"
              v-on:click="onDownloadExcel('XLSX')"
              type="submit"
              size="sm"
              variant="primary"
            >
              <i class="fa fa-dot-circle-o"></i>Download as XLSX
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <div>
          <v-client-table
            :columns="columns"
            :data="DataFromDB"
            :options="options"
            :theme="theme"
            id="dataTable"
          >
            <div slot="Action" slot-scope="props">
              <b-button :disabled="props.row.Flag" type="submit" variant="success" block>Refund</b-button>
            </div>
            <!--<div slot="Action" slot-scope="props">
              <b-button
                :disabled="props.row.Flag"
                @click="submitTransferId(props.index,props.row.BookingID)"
                type="submit"
                variant="success"
                block
              >Resend Ticket</b-button>
            </div>-->
          </v-client-table>
        </div>
      </b-card-body>
      <!-- Data From DB -->
    </b-card>
  </div>
</template>

<script>
import { Switch as cSwitch } from "@coreui/vue";
import { Event } from "vue-tables-2";
import MasterService from "@/services/MasterService";
import OrganizationService from "@/services/OrganizationService";
import ReportService from "@/services/ReportService";
import CinemaService from "@/services/CinemaService";
import MovieService from "@/services/MovieService";

// jspdf-autotable
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BASE64IMAGE } from "@/helper/baseImage";
import { dashboardName,WANASHAdashboardName } from "@/helper/constant";
import XLSX from "xlsx";
import { pdfFillColor } from "@/helper/constant";

export default {
  name: "CompareTransactions",
  components: {
    Event,
    cSwitch
  },
  data: function() {
    return {
      userPermission: true,

      ToDate: "",
      FromDate: "",
      ReadExcel: "",
      ExcelData: [],
      UnMatchData: [],
      UnMatchAmount: [],
      UnMatchDataFromDB: [],
      UnMatchAmountFromDB: [],
      DifferenceData: [],
      DataFromDB: [],
      MatchData: [],
      NonReconciled: [],
      ExcelRow: {
        code: null,
        policy: null,
        certificate: null
      },
      ShowDate: "",
      report: "",
      columns:
        localStorage.getItem("UserRole") == "Cinema"
          ? [
              "Sr",
              "OrderID",
              "Sender",
              "Date",
              "Code",
              "Channel",
              "Phone",
              "Amount",
              "GTVDifference",
              "CommissionDifference"
            ]
          : [
              "Sr",
              "OrderID",
              "Sender",
              "Date",
              "Code",
              "Channel",
              "Phone",
              "Amount",
              "GTVDifference",
              "CommissionDifference"
            ],
      //TranscationReport
      data: [],
      options: {
        headings: {
          Sr: "Sr. No.",
          OderID: "Order ID",
          Sender: "Sender",
          Date: "Date",
          Code: "Code",
          Channel: "Channel",
          Phone: "Phone",
          Amount: "Amount",
          GTVDifference: "Difference",
          CommissionDifference: "Commission Difference"
        },

        sortable: [
          "Sr",
          "OrderID",
          "Sender",
          "Date",
          "Code",
          "Channel",
          "Phone",
          "Amount",
          "GTVDifference"
        ],
        filterable: [
          "Sr",
          "OrderID",
          "Sender",
          "Date",
          "Code",
          "Channel",
          "Phone",
          "Amount",
          "GTVDifference"
        ],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      downloadOptions: [
        {
          text: "Select type",
          value: ""
        },
        {
          text: "xls",
          value: "xls"
        },
        {
          text: "pdf",
          value: "pdf"
        }
      ],
      downloadType: "",
      useVuex: false,
      theme: "bootstrap4",
      theme1: "bootstrap4",
      theme2: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      //ShowReportsDetails
      columns1: ["SuccessNumberOfTickets", "SuccessPrice"],
      data1: [],
      options1: {
        headings: {
          SuccessNumberOfTickets: "No of Tickets",
          SuccessPrice: "Success Total Ticket Price + Convenience Fee"
        }
      },
      columns2: ["FailedNumberOfTickets", "FailedPrice"],
      data2: [],
      options2: {
        headings: {
          FailedNumberOfTickets: "No of Tickets",
          FailedPrice: "Failed Total Ticket Price + Convenience Fee"
        }
      }
    };
  },
  beforeMount() {
    if (
      localStorage.getItem("UserRole") &&
      localStorage.getItem("UserRole") == "Cinema"
    ) {
      this.userPermission = false;
    } else {
      //method to be called here
    }
  },
  mounted() {
    //this.submit();
    this.NonReconciledData();
  },
  methods: {
    onDownloadExcel: function(type) {
      if (this.NonReconciled.length > 0) {
        var dataOne = XLSX.utils.json_to_sheet(this.NonReconciled);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, dataOne, "Non Reconciled"); // sheetAName is name of Worksheet
        XLSX.writeFile(
          wb,
          `${WANASHAdashboardName.toUpperCase()}_NON_RECONCILED_DATA_${new Date().toDateString()}.xlsx`
        );
      } else {
        this.showMessageSnackbar("No data available");
      }
      //alert("Not yet working");
    },
    submit: function() {
      this.UnMatchData = [];
      this.UnMatchAmount = [];
      this.UnMatchDataFromDB = [];
      this.UnMatchAmountFromDB = [];
      this.MatchData = [];
      this.DifferenceData = [];
      let payload = {};
      if (this.FromDate.length > 0) {
        payload["FromDate"] = this.FromDate;
      }
      if (this.ToDate.length > 0) {
        payload["ToDate"] = this.ToDate;
      }

      if (this.data.length > 0) {
        payload["ExcelData"] = JSON.stringify(this.data);
      }

      console.log(payload);

      ReportService.getTransactionDataForCompare(payload)
        .then(response => {
          const { data } = response;
          if (data.Status) {
            this.DataFromDB = data.Data;
            //this.data = data.Data;
            console.log(data.Data);
            var result1 = this.data;
            var result2 = data.Data;
            var result = {};
            this.compareArray(result1, result2, "excel");
            this.compareArray(result2, result1, "db");
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          console.log("Catch on 1 ", error);

          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    updateDifference: function() {
      let payload = {};
      payload["DifferenceData"] = JSON.stringify(this.DifferenceData);
      ReportService.updateDifferenceData(payload)
        .then(response => {
          const { data } = response;
          if (data.Status) {
            this.NonReconciledData();
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          console.log("Catch on 1 ", error);

          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    NonReconciledData: function() {
      this.NonReconciled = [];
      let payload = {};
      ReportService.NonReconciled(payload)
        .then(response => {
          const { data } = response;
          if (data.Status) {
            this.NonReconciled = data.Data;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          console.log("Catch on 1 ", error);

          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    handleFile: function(e) {
      this.data = [];
      let vm = this;
      var files = e.files,
        f = files[0];
      var reader = new FileReader();
      reader.onload = function(e) {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: "array" });
        var workSheetOne = workbook.Sheets[workbook.SheetNames[0]];
        var excelToJson = XLSX.utils.sheet_to_json(workSheetOne, { header: 1 });
        for (var i = 0; i < excelToJson.length; i++) {
          if (i != 0) {
            if (excelToJson[i].length > 0) {
              if (excelToJson[i][0] != "") {
                let tempData = {
                  OrderID: excelToJson[i][0],
                  Sender: excelToJson[i][1],
                  Date: excelToJson[i][2],
                  Code: excelToJson[i][3],
                  Channel: excelToJson[i][4],
                  Phone: excelToJson[i][5],
                  Amount: excelToJson[i][6]
                };
                vm.ExcelData.push(tempData);
              }
            }
          }
        }
      };
      reader.readAsArrayBuffer(f);
      this.data = vm.ExcelData;
    },
    compareArray: function(array1, array2, type) {
      // this.UnMatchData = [];
      // this.UnMatchAmount = [];
      // this.UnMatchDataFromDB = [];
      // this.UnMatchAmountFromDB = [];
      // this.MatchData = [];
      // this.DifferenceData = [];
      let checkStatus = false;
      for (var i = 0; i < array1.length; i++) {
        for (var j = 0; j < array2.length; j++) {
          if (array1[i].Code == array2[j].Code) {
            if (array1[i].Amount == array2[j].Amount) {
              if (type == "excel") {
                this.MatchData.push(array1[i].Code);

                let tempArray = {
                  OrderID: array1[i].OrderID,
                  Code: array1[i].Code,
                  Amount: 0
                };
                this.DifferenceData.push(tempArray);
              }
              checkStatus = true;
            } else {
              console.log("Else Execute :", "Entered Else");
              if (type == "excel") {
                array1[i].GTVDifference = array1[i].Amount - array2[j].Amount;
                this.UnMatchAmount.push(array1[i]);
                let tempArray = {
                  OrderID: array1[i].OrderID,
                  Code: array1[i].Code,
                  Amount: array1[i].Amount - array2[j].Amount
                };
                console.log("Temp Difference Array :", tempArray);
                this.DifferenceData.push(tempArray);
              } else if (type == "db") {
                this.UnMatchAmountFromDB.push(array1[i]);
              }
              checkStatus = true;
            }
          }
        }
        if (checkStatus) {
          checkStatus = false;
        } else {
          if (type == "excel") {
            this.UnMatchData.push(array1[i]);
          } else if (type == "db") {
            this.UnMatchDataFromDB.push(array1[i]);
          }
        }
      }
      console.log(" Unmatched Reference ", this.UnMatchData);
      console.log(" Unmatched Amount ", this.UnMatchAmount);
      console.log(" Matched Data ", this.MatchData);
      console.log(" Difference Data ", this.DifferenceData);
      this.updateDifference();
    },
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>

<style>
</style>
